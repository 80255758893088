import React, { useEffect, useState } from 'react';
import { ZoomProvider } from 'providers/ZoomProvider';
import { withErrorBoundary } from 'utils/withErrorBoundary';
import { syncI18N } from './utils/sync-i18n';
import 'antd/dist/antd.min.css';
import './App.scss';

import Routes from './Routes';

if (process.env.NODE_ENV === 'development') {
  syncI18N();
}

const App = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <ZoomProvider loaded={loaded} initialFactor={1}>
      <Routes />
    </ZoomProvider>
  );
};

export default withErrorBoundary(App);
